<template>
    <div class="order-verify">
        <Header />
        <div class="content">
            <div class="container" v-if="productDetail">
                <div class="title">确认订单信息</div>
                <div class="product">
                    <div class="sub-title">购买课程</div>
                    <div class="info">
                        <div class="item item-cover">
                            <div class="label"></div>
                            <div class="value">
                                <img class="cover" :src="productDetail.bannerImageUrl" />
                            </div>
                        </div>
                        <div class="item auto-width">
                            <div class="label">课程名称</div>
                            <div class="value">
                                <div class="name">{{ productDetail.title }}</div>
                                <div class="teacher">
                                    {{ productDetail.courseTeacher ? productDetail.courseTeacher.name : '-' }}
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="label">价格</div>
                            <div class="value center">¥{{ productDetail.price / 100 }}</div>
                        </div>
                        <div class="item item-price">
                            <div class="label">付款</div>
                            <div class="value center">
                                <span class="price" v-if="userPaidStatus.fee">¥{{ userPaidStatus.fee / 100 }}</span>
                                <span v-else class="price">¥{{ productDetail.price / 100 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mobile-product">
                    <div class="sub-title">购买课程</div>
                    <div class="info">
                        <img class="cover" :src="productDetail.bannerImageUrl" />
                        <div>
                            <div class="name">{{ productDetail.title }}</div>
                            <div class="teacher">
                                {{ productDetail.courseTeacher ? productDetail.courseTeacher.name : '-' }}
                            </div>
                        </div>
                    </div>
                    <div class="hr"></div>
                    <div class="other">
                        <div class="label">价&emsp;&emsp;&emsp;格:</div>
                        <div class="value price">¥{{ productDetail.price / 100 }}</div>
                    </div>
                </div>
                <div class="pay-info">
                    <div class="sub-title">支付方式</div>
                    <div class="radio-group">
                        <div v-if="payTypeList.includes('wxpay')" class="radio" @click="onSwitchPayType('wxpay')">
                            <van-icon v-if="payType === 'wxpay'" name="checked" />
                            <van-icon v-else name="circle" />
                            <img src="@/assets/images/wxpay-icon.png" />
                            <span>微信支付</span>
                        </div>
                        <div v-if="payTypeList.includes('alipay')" class="radio" @click="onSwitchPayType('alipay')">
                            <van-icon v-if="payType === 'alipay'" name="checked" />
                            <van-icon v-else name="circle" />
                            <img src="@/assets/images/alipay-icon.png" />
                            <span>支付宝支付</span>
                        </div>
                    </div>
                    <div class="agm-price">
                        <div class="agm" v-if="productDetail.agreementId" @click="onSwitchAgm">
                            <van-icon v-if="!activeAgm" name="circle" />
                            <van-icon v-else name="checked" />
                            <div>
                                我已阅读并同意<span @click.stop="isShowPreviewAgmPopup = true">《购买协议》</span>
                            </div>
                        </div>
                        <div v-else></div>
                        <div class="price">
                            <span>需支付金额:</span>
                            <span class="price-num" v-if="userPaidStatus.fee">{{ userPaidStatus.fee / 100 }}</span>
                            <span v-else class="price-num">¥{{ productDetail.price / 100 }}</span>
                        </div>
                    </div>
                    <div class="order">
                        <div class="buy-btn" @click="onPayOrder">立即支付</div>
                    </div>
                </div>
                <div class="mobile-pay-info">
                    <div class="agm" v-if="productDetail.agreementId" @click="onSwitchAgm">
                        <van-icon v-if="!activeAgm" name="circle" />
                        <van-icon v-else name="checked" />
                        <div>我已阅读并同意<span @click.stop="isShowPreviewAgmPopup = true">《购买协议》</span></div>
                    </div>
                    <div class="payment">
                        <div class="buy-btn" @click="onMoiblePayOrder">立即支付</div>
                        <div class="price">
                            <span>需支付金额:</span>
                            <span class="price-num" v-if="userPaidStatus.fee">¥{{ userPaidStatus.fee / 100 }}</span>
                            <span v-else class="price-num">¥{{ productDetail.price / 100 }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Fotter />

        <PreviewAgmPopup v-if="productDetail" :isShow.sync="isShowPreviewAgmPopup" :agmId="productDetail.agreementId" />
        <PayPopup
            v-if="productDetail"
            :payType="payType"
            :payPrice="userPaidStatus.fee || productDetail.price"
            :isShow.sync="isShowPayPopup"
            :codeText="wechatPayCode"
            @payStatus="onQueryPayStatus"
        />
    </div>
</template>
<script>
import { coursePackageList, getPayType } from '@/api/courseApi.js'
import { fetchOrder, shortText, getPaidStatus, wechatPay } from '@/api/userApi.js'
import PreviewAgmPopup from './components/PreviewAgmPopup.vue'
import PayPopup from './components/PayPopup.vue'
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'orderVerify',
    data() {
        return {
            productDetail: null,
            activeAgm: false,
            // alipay wxpay
            payType: 'alipay',
            payTypeList: [],
            userPaidStatus: {},

            isShowPreviewAgmPopup: false,
            isShowPayPopup: false,
            wechatPayCode: '',
        }
    },
    components: { Header, Fotter, PreviewAgmPopup, PayPopup },
    computed: { ...mapGetters(['deviceInfo', 'userInfo', 'WX_APPID', 'ALI_APPID']) },
    created() {
        this.coursePackageListApi()
        this.getPayTypeApi()
        this.getPaidStatusApi()
    },
    methods: {
        onSwitchAgm() {
            this.activeAgm = !this.activeAgm
        },
        onSwitchPayType(payType) {
            this.payType = payType
        },
        onPayOrder() {
            const { payType, productDetail, activeAgm } = this
            const { packageNo } = this.$route.params

            if (!payType) {
                return this.$toast('请选择支付方式')
            }

            if (productDetail && productDetail.agreementId && !activeAgm) {
                this.$dialog
                    .confirm({
                        className: 'confirm-dialog',
                        title: '友情提示',
                        message: `我已阅读并同意<span id="confirmDialogAgm">《购买协议》</span>`,
                        confirmButtonText: '同意并继续',
                    })
                    .then(() => {
                        this.activeAgm = true
                        this.onPayOrder()
                        document.onclick = null
                    })
                    .catch(() => {
                        document.onclick = null
                    })

                document.onclick = (event) => {
                    if (event.target.id === 'confirmDialogAgm') {
                        this.isShowPreviewAgmPopup = true
                    }
                }
                return
            }

            // 开始支付
            const params = {
                userId: this.userInfo.id,
                productType: 1,
                packageNo: packageNo,
                productNo: packageNo,
                agreementStatus: false,
                orderDetail: {
                    tradeType: 'pc_pay',
                    tradeProvider: payType,
                    payFee: productDetail.price,
                },
            }
            if (productDetail.agreementId) {
                params.agreementStatus = true
            }
            if (payType === 'wxpay') {
                params.appId = this.WX_APPID
                params.orderDetail.tradeType = 'NATIVE'
            } else if (payType === 'alipay') {
                params.appId = this.ALI_APPID
                params.orderDetail.tradeType = 'pc_pay'
            }

            this.wechatPayCode = ''
            fetchOrder(params).then((res) => {
                if (payType === 'wxpay') {
                    this.wechatPayCode = res.data.codeUrl
                } else if (payType === 'alipay') {
                    shortText(res.data.payShortKey).then((res) => {
                        if (res.data) {
                            window.open(res.data)
                        } else {
                            this.$toast('创建订单异常，请稍后再试')
                        }
                    })
                }
                this.isShowPayPopup = true
            })
        },
        // 移动端 微信环境内直接支付，微信外支付宝支付
        onMoiblePayOrder() {
            const { productDetail, activeAgm } = this
            const { packageNo } = this.$route.params
            if (productDetail && productDetail.agreementId && !activeAgm) {
                return this.$toast('请同意购买协议')
            }
            const payType = this.deviceInfo.wechat ? 'wxpay' : 'alipay'
            const params = {
                userId: this.userInfo.id,
                productType: 1,
                packageNo: packageNo,
                productNo: packageNo,
                agreementStatus: false,
                orderDetail: {
                    tradeType: 'pc_pay',
                    tradeProvider: payType,
                    payFee: productDetail.price,
                },
            }
            if (productDetail.agreementId) {
                params.agreementStatus = true
            }
            if (payType === 'wxpay') {
                params.openId = this.userInfo.openId
                params.appId = this.WX_APPID
                params.orderDetail.tradeType = 'JSAPI'
            } else if (payType === 'alipay') {
                params.appId = this.ALI_APPID
                params.orderDetail.tradeType = 'wap_pay'
            }

            fetchOrder(params).then((res) => {
                if (payType === 'wxpay') {
                    wechatPay(
                        {
                            ...res.data,
                            package: res.data.packageValue,
                        },
                        (data) => {
                            if (data.err_msg == 'get_brand_wcpay_request:ok') {
                                this.$toast({
                                    className: 'success-toast',
                                    message: '支付成功',
                                    duration: 2000,
                                })
                                setTimeout(() => {
                                    this.onQueryPayStatus()
                                }, 1000)
                            } else if (data.err_msg == 'get_brand_wcpay_request:fail') {
                                this.$toast('支付失败')
                            }
                        }
                    )
                } else if (payType === 'alipay') {
                    shortText(res.data.payShortKey).then((res) => {
                        if (res.data) {
                            window.open(res.data)
                            setTimeout(() => {
                                this.onQueryPayStatus()
                            }, 3000)
                        } else {
                            this.$toast('创建订单异常，请稍后再试')
                        }
                    })
                }
            })
        },
        onQueryPayStatus() {
            const { packageNo } = this.$route.params
            if (packageNo && this.userInfo && this.userInfo.id) {
                getPaidStatus(packageNo).then((res) => {
                    if (res.data && res.data.status) {
                        this.$toast.success('支付成功')
                        setTimeout(() => {
                            this.$router.push('/course')
                        }, 1500)
                    } else {
                        this.$toast.success('支付未完成')
                        this.$router.push('/account/order')
                    }
                })
            }
        },
        coursePackageListApi() {
            const { packageNo } = this.$route.params
            coursePackageList(packageNo).then((res) => {
                if (res.data && res.data.length !== 0) {
                    const productDetail = res.data[0]
                    this.productDetail = productDetail
                } else {
                    this.$toast('暂无课程')
                }
            })
        },
        getPayTypeApi() {
            getPayType().then((res) => {
                if (res.data) {
                    this.payTypeList = res.data.split(',')
                    this.payType = this.payTypeList[0]
                }
            })
        },
        getPaidStatusApi() {
            const { packageNo } = this.$route.params
            if (packageNo && this.userInfo && this.userInfo.id) {
                getPaidStatus(packageNo).then((res) => {
                    this.userPaidStatus = res.data
                    // 是否支付
                    if (res.data && res.data.status) {
                        this.$dialog
                            .alert({
                                title: '您已购买此课程',
                                theme: 'round-button',
                                confirmButtonText: '前往学习',
                            })
                            .then(() => {
                                this.$router.push('/course')
                            })
                    }
                })
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.order-verify {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .content {
        flex-grow: 1;
    }
}
.order-verify {
    background: $theme-bg-color;
    .container {
        max-width: $center-width;
        padding: 24px;
        margin: 0 auto;
    }
    .title {
        height: 24px;
        font-size: 24px;
        color: #33312f;
        font-weight: 700;
    }
    .sub-title {
        font-size: 16px;
        color: #33312f;
        line-height: 22px;
        font-weight: 700;
    }
    .product {
        width: 100%;
        height: 262px;
        background: #ffffff;
        padding: 24px 32px 20px 32px;
        margin-top: 24px;
        box-sizing: border-box;
        .info {
            display: flex;
            justify-content: space-between;
            height: 176px;
            border: 1px solid #f5f5f5;
            box-sizing: border-box;
            margin-top: 20px;
            .item {
                flex-grow: 1;
                min-width: 90px;
                .label {
                    line-height: 44px;
                    text-align: center;
                    height: 44px;
                    background: #f5f5f5;
                }
            }
            .item-cover {
                flex-grow: 0;
                flex-shrink: 0;
                width: 212px;
            }
            .item-price {
                min-width: 60px;
            }
            .value {
                height: 132px;
                font-size: 14px;
                color: #33312f;
                line-height: 14px;
                .cover {
                    margin: 20px 28px 20px 20px;
                    width: 164px;
                    height: 92px;
                    object-fit: cover;
                }
                .name {
                    font-size: 16px;
                    color: #33312f;
                    line-height: 22px;
                    margin: 20px 0 12px;
                }
                .teacher {
                    color: #66625e;
                }
                .price {
                    color: #fa2f19;
                }
                &.center {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .auto-width {
                .label {
                    text-align: left;
                }
            }
        }
    }
    .pay-info {
        background: #ffffff;
        width: 100%;
        height: 280px;
        margin-top: 24px;
        padding: 24px 50px 20px 32px;
        overflow: hidden;
        box-sizing: border-box;
        .radio-group {
            margin-top: 24px;
            display: flex;
            .radio {
                cursor: pointer;
                margin-right: 20px;
                width: 198px;
                height: 52px;
                background: #ffffff;
                border-radius: 4px;
                border: 1px solid #e6e6e6;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #33312f;
                line-height: 20px;
                .van-icon {
                    color: #e6e6e6;
                    font-size: 18px;
                    margin: 0 14px 0 15px;
                }
                .van-icon-checked {
                    color: $theme-color;
                }
                img {
                    margin-right: 8px;
                    background: none;
                }
            }
        }
        .agm-price {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 24px;
            .agm {
                display: flex;
                cursor: pointer;
                font-size: 14px;
                .van-icon {
                    font-size: 16px;
                    margin-right: 8px;
                }
                .van-icon-checked {
                    color: $theme-color;
                }
                span {
                    color: $theme-color;
                    font-weight: 700;
                }
            }
            .price {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #33312f;
                line-height: 22px;
                .price-num {
                    height: 38px;
                    font-size: 28px;
                    font-weight: 700;
                    color: #fa2f19;
                    line-height: 38px;
                    margin-left: 8px;
                }
            }
        }
        .order {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 28px;
            .order-num {
                font-size: 14px;
                color: #4a4a4a;
                line-height: 20px;
            }
            .buy-btn {
                width: 156px;
                height: 40px;
                background: $theme-color;
                border-radius: 4px;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                color: #ffffff;
                cursor: pointer;
            }
        }
    }
}

.mobile-product {
    display: none;
    margin: 0 auto;
    padding: 16px;
    .info {
        margin-top: 16px;
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        height: auto;
        border: none;
        background: #f8f8f8;
        .cover {
            width: 100px;
            height: auto;
            flex-shrink: 0;
        }
        > div {
            flex-grow: 1;
            margin-left: 12px;
            padding: 4px 0;
        }
        .name {
            font-size: 14px;
            font-weight: 700;
            line-height: 1.4em;
        }
        .teacher {
            margin-top: 8px;
            font-size: 14px;
            color: #666666;
        }
    }
    .other {
        display: flex;
        align-items: center;
        line-height: 1.4em;
        .label {
            width: 80px;
            flex-shrink: 0;
            color: #666666;
            font-size: 12px;
        }
        .value {
            font-size: 12px;
        }
        .price {
            color: #fa2f19;
        }
    }
    .hr {
        width: 100%;
        height: 1px;
        background: #d7d7d7;
        margin: 16px 0;
    }
}

.mobile-pay-info {
    display: none;
    .agm {
        display: flex;
        cursor: pointer;
        font-size: 14px;
        margin: 18px 16px 0;
        .van-icon {
            font-size: 16px;
            margin-right: 8px;
        }
        .van-icon-checked {
            color: $theme-color;
        }
        span {
            color: $theme-color;
            font-weight: 700;
        }
    }
    .payment {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: 64px 16px 36px;
        .price {
            font-size: 14px;
            .price-num {
                font-size: 20px;
                color: #fa2f19;
            }
        }
        .buy-btn {
            width: 120px;
            height: 40px;
            background: $theme-color;
            border-radius: 4px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            cursor: pointer;
            color: #ffffff;
            margin-left: 24px;
        }
        .buy-btn-disabled {
            background: #eee;
            color: #999;
        }
    }
}

@media screen and (max-width: 720px) {
    .order-verify {
        background: #fff;
        .container {
            width: auto;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            overflow: hidden;
        }
        .title {
            display: none;
        }
        .product {
            display: none;
        }
        .mobile-product {
            display: block;
        }
        .pay-info {
            display: none;
        }
        .mobile-pay-info {
            display: block;
        }
    }
}
</style>
<style lang="scss">
.confirm-dialog {
    #confirmDialogAgm {
        cursor: pointer;
        color: #df7e2f;
    }
}
</style>
