<template>
    <van-popup class="wechatPayPopup" @close="onClose" v-model="show" closeable get-container="body">
        <div class="container">
            <div v-if="payType === 'wxpay'" class="title">微信支付</div>
            <div v-if="payType === 'alipay'" class="title">支付宝支付</div>
            <div class="desc">
                <span>支付金额: </span>
                <span class="price-num">{{ payPrice / 100 }}</span>
            </div>
            <template v-if="payType === 'wxpay'">
                <div class="desc">二维码30分钟内有效，请尽快支付</div>
                <canvas class="wx-qrcode" ref="canvas"></canvas>
                <div class="desc">使用微信扫码支付</div>
            </template>

            <template v-if="payType === 'alipay'">
                <div class="desc">支付进行中，完成后请确认支付成功</div>
                <img class="ali-pay-icon" src="@/assets/images/pay-loading.png" />
                <div class="desc">支付进行中…</div>
            </template>
            <div class="opts">
                <van-button plain @click="onClose">取消</van-button>
                <van-button class="submit-btn" @click="onPayStatus">我已支付</van-button>
            </div>
        </div>
    </van-popup>
</template>
<script>
const QRCode = require('qrcode')

export default {
    name: 'wechatPayPopup',
    props: ['isShow', 'codeText', 'payType', 'payPrice'],
    data() {
        return {
            show: false,
            nickname: '',
        }
    },
    watch: {
        isShow: function (isShow) {
            this.show = isShow
            if (isShow) {
                this.onCreatePayQrcode()
            }
        },
    },
    methods: {
        onClose() {
            this.$emit('update:isShow', false)
        },
        onCreatePayQrcode() {
            if (this.codeText && this.payType === 'wxpay') {
                this.$nextTick(() => {
                    this.$toast.loading({
                        duration: 0,
                        forbidClick: true,
                    })
                    QRCode.toCanvas(
                        this.$refs.canvas,
                        this.codeText,
                        { width: 228, height: 228, margin: 2, errorCorrectionLevel: 'L' },
                        (err) => {
                            this.$toast.clear()
                            if (err) this.$toast('生成支付二维码失败，请稍后再试')
                        }
                    )
                })
            }
        },
        onPayStatus() {
            this.$emit('payStatus')
        },
    },
}
</script>
<style lang="scss" scoped>
.wechatPayPopup {
    width: 440px;
    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        .title {
            padding: 0;
            width: 100%;
            height: 60px;
            font-size: 20px;
            color: #33312f;
            line-height: 60px;
            border-bottom: 1px solid #e6e6e6;
            font-weight: 700;
            text-align: center;
        }
        .desc {
            margin-top: 20px;
            height: 16px;
            font-size: 16px;
            font-weight: 400;
            color: #606266;
            line-height: 16px;
            text-align: center;
            font-family: PingFangSC-Regular, PingFang SC;
            .price-num {
                font-weight: 600;
                color: $theme-color;
            }
        }
        .wx-qrcode {
            margin-top: 24px;
            width: 228px;
            height: 228px;
        }
        .wx-qrcode + .desc {
            margin-top: 16px;
        }
        .ali-pay-icon {
            width: 280px;
            height: 174px;
            margin-top: 51px;
            background: none;
        }
        .ali-pay-icon + .desc {
            margin-top: 43px;
        }

        .opts {
            display: flex;
            justify-content: space-around;
            width: 100%;
            box-sizing: border-box;
            padding: 24px 92px 28px;
            .van-button {
                width: 100px;
                height: 36px;
                border-radius: 4px;
                font-size: 14px;
                margin: 0 auto;
            }
            .submit-btn {
                border: none;
                background: $theme-color;
                color: #ffffff;
            }
        }
    }
}
</style>
