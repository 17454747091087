<template>
    <van-popup
        class="previewAgmPopup"
        :class="deviceInfo.mobile ? 'mobile-previewAgmPopup' : ''"
        :closeable="true"
        @close="onClose"
        v-model="show"
        get-container="body"
    >
        <iframe :src="`${PROJECT_PATH}/agm.html?agmId=${agmId}`"></iframe>
    </van-popup>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'previewAgmPopup',
    props: ['isShow', 'agmId'],
    data() {
        return {
            show: false,
        }
    },
    computed: { ...mapGetters(['deviceInfo', 'PROJECT_PATH']) },
    watch: {
        isShow: function (isShow) {
            this.show = isShow
        },
    },
    methods: {
        onClose() {
            this.$emit('update:isShow', false)
        },
    },
}
</script>
<style lang="scss" scoped>
.previewAgmPopup {
    width: 800px;
    height: 600px;
    padding: 20px 0;
    iframe {
        width: 100%;
        margin: 0 auto;
        display: block;
        height: 100%;
    }
}
.mobile-previewAgmPopup {
    width: 90vw;
    max-width: 800px;
    height: 80vh;
    max-height: 600px;
}
</style>
